import React from "react"
import {NewsLayout} from "sections/News/"
import useNewsData from "shared/Hooks/News/useNewsData"
import {TextBlock} from "src/sections/News/NewsLayout/TextBlock";
import {NEWS_19} from "types/News"
import {Images} from '../../sections/News/NewsLayout/Images';

const Article = () => {
  const data = useNewsData(NEWS_19);

  return (
    <NewsLayout data={data}>
      <TextBlock data={data.texts.first}/>
      <Images data={data.images.first}/>
      <Images data={data.images.second}/>
    </NewsLayout>
  )
}

export default Article
